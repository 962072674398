
import { useTranslation } from 'next-i18next';
import styles from './index.module.less';
import { CloseOutlined } from '@ant-design/icons';
import { Affix } from 'antd';
import { useConfigProvider } from '@/context/ConfigProvider';
import Link from 'next/link';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';

const ArticleBottomAlert = () => {
    const { t } = useTranslation('common')
    const { onShowArticleBottomAlert, isShowArticleBottomAlert, isOnBabyNameDetailPage } = useConfigProvider()

    return <>
        {isShowArticleBottomAlert && <Affix offsetBottom={0} style={{ zIndex: '9999' }}>
            <div className={styles.content}>
                <CloseOutlined className={styles.closeIcon} onClick={(e) => {
                    e?.stopPropagation()
                    onShowArticleBottomAlert(false)
                }} />
                <Link
                    href={'/'}
                    onClick={() => {
                        if (isOnBabyNameDetailPage) {
                            ga_logEvent(EventName.App_Boby_Name_Detail_Banner)
                        }
                    }}
                >
                    <div className={styles.contentInfo}>
                        <div>
                            {t('Reveal What Your Baby Will Look Like')}
                        </div>
                        <span>
                            {t('Meet my baby now >')}
                        </span>
                    </div>
                </Link>

            </div>
        </Affix>}
    </>
}

export default ArticleBottomAlert;